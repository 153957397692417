// import React from 'react';
// import ReactDOM from 'react-dom';
// import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
// import { store } from './store/Store';
// import App from './App';
// import './data';

// ReactDOM.render(
//   <Provider store={store}>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </Provider>,

//   document.getElementById('root'),
// );
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import Spinner from "./views/spinner/Spinner";
import { Provider } from "react-redux";
import { configureStore } from "./redux/Store";
import ThemeSelector from './layouts/theme/ThemeSelector';
/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */

const authority = localStorage.getItem("passwordResetURL") || "";
let _msalConfig = { ...msalConfig };
if (authority) {
  _msalConfig = {
    ...msalConfig,
    auth: {
      ...msalConfig.auth,
      authority,
    },
  };
  localStorage.removeItem("passwordResetURL");
}

export const msalInstance = new PublicClientApplication(_msalConfig);

/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */
ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <Suspense fallback={<Spinner />}>
      <Provider store={configureStore()}>
        <BrowserRouter>
          <ThemeSelector>
            <App />
          </ThemeSelector>
        </BrowserRouter>
      </Provider>
    </Suspense>
  </MsalProvider>,
  document.getElementById("root")
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
