import BaseService from "../base-service";
import StandardResponse, { standardResponse } from "../standard-response.model";

export default class CommonService {
  getPermissions = async (): Promise<StandardResponse<any>> => {
    try {
      const res = await BaseService({
        url: `user/permission`,
        method: "get",
      });
      return standardResponse(res);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  GetSystemConfiguration = async (): Promise<StandardResponse<any>> => {
    try {
      const res = await BaseService({
        url: `/meter/dashboard/configuration`,
        method: "get",
      });
      return standardResponse(res);
    } catch (e) {
      return Promise.reject(e);
    }
  };
}
