import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
import { ROUTER } from '../utils';
/****Layouts*****/

const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
/*******MDM Routes ************/

const NEM12Component = Loadable(lazy(() => import("../views/nem12/nem12.component")));
/*******MDM Routes ************/
/*****Routes******/

const ThemeRoutes = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', name: 'Home', element: <Navigate to={ROUTER.DASHBOARD} /> },
      { path: ROUTER.NEM12, name: 'NEM12', exact: true, element: <NEM12Component /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  // {
  //   path: '/auth',
  //   element: <BlankLayout />,
  //   children: [
  //     { path: '404', element: <Error /> },
  //     { path: '*', element: <Navigate to="/auth/404" /> },
  //     { path: 'registerformik', element: <RegisterFormik /> },
  //     { path: 'loginformik', element: <LoginFormik /> },
  //     { path: 'maintanance', element: <Maintanance /> },
  //     { path: 'lockscreen', element: <LockScreen /> },
  //     { path: 'recoverpwd', element: <RecoverPassword /> },
  //   ],
  // },
];

export const getThemeRoutes = (props: any) => {
  return [
    {
      path: '/',
      element: <FullLayout {...props} />,
      children: [
        { path: '/', name: 'NEM12', element: <Navigate to={ROUTER.NEM12} /> },
        { path: ROUTER.NEM12, name: 'NEM12', exact: true, element: <NEM12Component /> },
        { path: '*', element: <Navigate to="/auth/404" /> },
      ],
    },
  ];
}

export default ThemeRoutes;
